<template>
  <div>
    <CustomerList />
  </div>
</template>

<script>
import CustomerList from "@/components/CustomerList";

export default {
  name: "CustomersView",
  components: { CustomerList },
}
</script>
