<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import CustomerDataService from "@/services/CustomerDataService";

export default {
  name: 'CustomerList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'customers' },
    vueSlug: { default: 'customer' }
  },
  data() {
    return {
      dataService: CustomerDataService,
      fieldConfig: CustomerDataService.getGridConfig(),
    }
  }
}
</script>
